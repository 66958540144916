import { useEffect } from 'react';

// tab의 부모의 애니메이션 등을 통해 width 사이즈가 변경될 때 indicator의 style을 변경해주는 hook
export function useResizeObserver(
  tabRef: React.RefObject<HTMLDivElement>,
  updateIndicatorStyle: () => void,
) {
  useEffect(() => {
    if (!tabRef.current) return;

    const resizeObserver = new ResizeObserver(updateIndicatorStyle);
    resizeObserver.observe(tabRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [updateIndicatorStyle]);
}
