import { type WindowSize, useWindowSize } from '@shared-hooks/use-window-size';
import { debounce } from 'lodash-es';
import { useEffect, useState } from 'react';

/**
 * lodash-es의 debounce를 사용하여 디바운스된 윈도우 크기를 반환하는 훅입니다.
 * @param delay 디바운스 딜레이 (ms)
 */
export const useDebouncedWindowSize = (delay = 250) => {
  const windowSize = useWindowSize();
  const [debouncedSize, setDebouncedSize] = useState<WindowSize>(windowSize);

  useEffect(() => {
    const debouncedHandler = debounce((size: WindowSize) => {
      setDebouncedSize(size);
    }, delay);

    debouncedHandler(windowSize);

    return () => {
      debouncedHandler.cancel();
    };
  }, [windowSize, delay]);

  return debouncedSize;
};
