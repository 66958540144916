import { numbericStringFormatCommas } from '@shared-utils/text/numberic-string-format-comma';
import type { Layer, SubLayer } from 'afterdoc-design-system/components/Atoms/PanelList/PanelList';

type RightContentRendererProps = { content: Layer['rightContent'] | SubLayer['rightContent'] };

export default function RightContentRenderer({ content }: RightContentRendererProps) {
  if (!content) return null;

  const isString = typeof content === 'string';
  const isNumber = typeof content === 'number';

  if (isString) {
    const displayContent = numbericStringFormatCommas(content);

    return <div className='text-Body12 text-black500'>{displayContent}</div>;
  }

  if (isNumber) {
    return <div className='text-Body12 text-black500'>{content.toLocaleString()}</div>;
  }

  return content;
}
