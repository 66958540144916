import { atom } from 'jotai';

// sessionStorage용 유틸리티 함수
const getSessionStorageValue = <T>(key: string, initialValue: T): T => {
  try {
    const item = sessionStorage.getItem(key);
    // item이 'undefined' 문자열이거나 null인 경우 initialValue 반환
    if (!item || item === 'undefined') {
      return initialValue;
    }
    return JSON.parse(item);
  } catch {
    return initialValue;
  }
};

const setSessionStorageValue = <T>(key: string, value: T): void => {
  try {
    // undefined나 null인 경우 해당 항목 제거
    if (value === undefined || value === null) {
      sessionStorage.removeItem(key);
      return;
    }

    // 빈 문자열이나 빈 객체 체크 (필요한 경우)
    if (
      (typeof value === 'string' && value.trim() === '') ||
      (typeof value === 'object' && value !== null && Object.keys(value).length === 0)
    ) {
      sessionStorage.removeItem(key);
      return;
    }

    sessionStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error('Error saving to sessionStorage:', error);
  }
};

export const createSessionAtom = <T>(key: string, initialValue: T) => {
  const baseAtom = atom<T>(getSessionStorageValue(key, initialValue));

  const sessionAtom = atom(
    (get) => get(baseAtom),
    (_get, set, newValue: T) => {
      set(baseAtom, newValue);
      setSessionStorageValue(key, newValue);
    },
  );

  return sessionAtom;
};
