import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import type { ReactNode } from 'react';

type ErrorTextProps = {
  errorText?: ReactNode;
  errorTextClassName?: string;
};

export default function ErrorText({ errorText, errorTextClassName }: ErrorTextProps) {
  return (
    <div className={customTwMerge('mt-4 text-Body10 text-red500', errorTextClassName)}>
      {errorText}
    </div>
  );
}
