import type React from 'react';
import {
  type CSSProperties,
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useResizeObserver } from '../hooks/use-resize-observer';

interface TabProviderProps<T> {
  value: T;
  onChange: (i: T) => void;
  tabRef: React.RefObject<HTMLDivElement>;
}

export interface TabContext<T> {
  indicatorStyle: CSSProperties;
  selectedValue?: T;
  onClick: (value: T, e: React.MouseEvent) => void;
}

export function createTabContext<T>() {
  return createContext<TabContext<T>>({
    indicatorStyle: {},
    onClick: () => null,
  });
}

export const tabContext = createTabContext();

export default function TabProvider<T>({
  value,
  onChange,
  tabRef,
  children,
}: PropsWithChildren<TabProviderProps<T>>) {
  const [indicatorStyle, setStyle] = useState<CSSProperties>({});
  const targetTab = useRef<HTMLElement | null>(null);

  const onClick = useCallback(
    (targetValue: T, e: React.MouseEvent) => {
      const { target } = e;

      onChange(targetValue);
      if (target instanceof HTMLElement) targetTab.current = target;
    },
    [onChange],
  );

  const setIndicatorStyle = (el: HTMLElement) => {
    const { offsetLeft, clientWidth } = el;

    setStyle({ width: `${clientWidth}px`, transform: `translateX(${offsetLeft}px)` });
  };

  const contextValue = useMemo(
    () => ({
      indicatorStyle,
      selectedValue: value,
      onClick: onClick as (value: unknown) => void,
    }),
    [indicatorStyle, value, onClick],
  );
  const updateIndicatorStyle = () => {
    const firstTab = tabRef.current?.querySelector('[aria-selected="true"]');

    if (firstTab instanceof HTMLElement) setIndicatorStyle(firstTab);
  };

  useResizeObserver(tabRef, updateIndicatorStyle);

  // indicator target element clientWidth값이 bold가 된 후 계산하기 위해 빼둔것
  useEffect(() => {
    if (!targetTab || !targetTab.current) return;

    setIndicatorStyle(targetTab.current);
    // if (value) onChange(value);
  }, [children, value, targetTab]);

  useEffect(() => {
    if (!tabRef || !tabRef.current) return;

    updateIndicatorStyle();
  }, [children, tabRef, value]);

  return <tabContext.Provider value={contextValue}>{children}</tabContext.Provider>;
}

export const useTabContext = () => {
  const context = useContext(tabContext);
  if (!context) throw new Error('tab context is not defined');

  return context;
};
