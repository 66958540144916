import { Color } from '@tailwind-base/styles/color';
import type { IconType } from 'afterdoc-design-system/components/Foundations/Icon/Icon';

export const DEFAULT_ICON_SIZES = [16, 20, 24, 28, 32, 40, 48] as const;
export const FIXED_ICON_SIZES = {
  'open-in-new': [12] as const,
  'birthday-cake-outline': [12] as const,
  cellphone: [12] as const,
  'person-alert': [12] as const,
  'chevron-up': [16, 28] as const,
  'chevron-down': [16, 20, 28] as const,
  'chevron-left': [16, 24, 40] as const,
  'chevron-right': [16, 20, 24] as const,
  close: [12, 16, 20, 24, 28] as const,
  'filter-outline': [16] as const,
  more: [20] as const,
  kakaotalk: [17, 20] as const,
  instagram: [17, 20] as const,
  afterdoc: [17] as const,
  'afterdoc-non': [17] as const,
  'smart-doctor': [17] as const,
  'smart-doctor-non': [17] as const,
  linked: [17] as const,
  'logo-kakaotalk': [24] as const,
  men: [20] as const,
  women: [20] as const,
};

export const FIXED_ICON_COLORS: {
  [key in IconType]?: (typeof Color)[keyof typeof Color] | 'none';
} = {
  afterdoc: 'none',
  'afterdoc-non': 'none',
  kakaotalk: 'none',
  'logo-kakaotalk': 'none',
  linked: 'none',
  'smart-doctor': 'none',
  'smart-doctor-non': 'none',
  women: 'none',
  men: 'none',
  'person-alert': Color.blue500,
};
