import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import RightContentRenderer from 'afterdoc-design-system/components/Atoms/PanelList/components/RightContentRenderer';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { AnimatePresence, motion } from 'framer-motion';
import { type ReactNode, useEffect, useState } from 'react';

export interface SubLayer {
  id?: string;
  text: ReactNode;
  state?: 'default' | 'focus';
  rightContent?: ReactNode;
}

export interface Layer {
  id?: string;
  title: ReactNode;
  state?: 'default' | 'focus';
  rightContent?: ReactNode;
  items: SubLayer[];
}

export interface PanelListProps {
  layers: Layer[];
  shouldProceedWithClick?: (layerIndex: number, itemIndex: number) => Promise<boolean>;
  onLayerClick?: (layerIndex: number, layerId?: string) => void;
  onItemClick?: (layerIndex: number, itemIndex: number, layerId?: string) => void;
  layerClassName?: string;
  subLayerClassName?: string;
}

export default function PanelList({
  layers,
  shouldProceedWithClick,
  onLayerClick,
  onItemClick,
  layerClassName,
  subLayerClassName,
}: PanelListProps) {
  const [focusedLayerIndex, setFocusedLayerIndex] = useState<number | null>(null);
  const [focusedItemIndex, setFocusedItemIndex] = useState<{
    [key: number]: number | null;
  }>({});
  const [isSubLayerOpen, setIsSubLayerOpen] = useState<{
    [key: number]: boolean;
  }>({});

  const [hasInteracted, setHasInteracted] = useState(false);

  const handleLayerClick = async (index: number, id?: string) => {
    if (shouldProceedWithClick) {
      const canContinue = await shouldProceedWithClick(index, 0);
      if (!canContinue) return;
    }

    setHasInteracted(true);
    setFocusedLayerIndex(index);
    if (focusedLayerIndex !== index) {
      setFocusedItemIndex({ [index]: 0 });
    }
    setIsSubLayerOpen((prev) => {
      const newState = { ...prev };
      newState[index] = !newState[index];
      return newState;
    });

    if (onLayerClick) {
      onLayerClick(index, id);
    }
  };

  const handleItemClick = async (layerIndex: number, itemIndex: number, layerId?: string) => {
    if (shouldProceedWithClick) {
      const canContinue = await shouldProceedWithClick(layerIndex, itemIndex);
      if (!canContinue) {
        return;
      }
    }
    setFocusedLayerIndex(layerIndex);
    setFocusedItemIndex({ [layerIndex]: itemIndex });

    if (onItemClick) {
      onItemClick(layerIndex, itemIndex, layerId);
    }
  };

  useEffect(() => {
    const layerIndex = layers.findIndex((layer) => layer.state === 'focus');

    if (layerIndex !== -1) {
      setFocusedLayerIndex(layerIndex);
      const initialItemIndex = layers[layerIndex].items.findIndex((item) => item.state === 'focus');
      setFocusedItemIndex({
        [layerIndex]: initialItemIndex !== -1 ? initialItemIndex : 0,
      });
    } else {
      setFocusedLayerIndex(null);
      setFocusedItemIndex({});
    }
  }, [layers]);

  useEffect(() => {
    const initialSubLayerOpenState = layers.reduce(
      (acc, _, index) => {
        acc[index] = true;
        return acc;
      },
      {} as { [key: number]: boolean },
    );
    setIsSubLayerOpen(initialSubLayerOpenState);
  }, [layers]);

  return (
    <div className='select-none'>
      {layers.map((layer, layerIndex) => {
        const hasSubLayer = layer.items.length > 0;

        return (
          <div key={layer.id ?? layerIndex}>
            <div className='px-8 py-4'>
              <div
                onClick={() => handleLayerClick(layerIndex, layer.id)}
                className={customTwMerge(
                  'mb-4 flex w-full cursor-pointer items-center justify-between rounded-r6 bg-white50 px-16 py-10 text-Header14',
                  focusedLayerIndex !== layerIndex && 'hover:bg-blueLight',
                  focusedLayerIndex === layerIndex ||
                    layer.items.some((_, itemIndex) => focusedItemIndex[layerIndex] === itemIndex)
                    ? 'bg-blue50 font-bold text-black500'
                    : 'bg-white',
                  layerClassName,
                )}>
                {layer.title}
                <div className='flex items-center gap-10'>
                  <RightContentRenderer content={layer.rightContent} />

                  {hasSubLayer && (
                    <Icon
                      name={isSubLayerOpen[layerIndex] ? 'chevron-down' : 'chevron-right'}
                      size={16}
                      color='black200'
                    />
                  )}
                </div>
              </div>
            </div>
            <AnimatePresence initial={false}>
              {hasSubLayer && isSubLayerOpen[layerIndex] && (
                <motion.ul
                  initial={
                    hasInteracted ? { height: 0, opacity: 0 } : { height: 'auto', opacity: 1 }
                  }
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.4 }}
                  className='overflow-hidden'>
                  {layer.items.map((item, itemIndex) => (
                    <li key={item.id ?? itemIndex} className='px-8 py-4'>
                      <div
                        onClick={() => handleItemClick(layerIndex, itemIndex, item.id)}
                        className={customTwMerge(
                          'flex w-full cursor-pointer items-center justify-between rounded-r6 bg-white50 px-32 py-11 text-Body12 ',
                          focusedItemIndex[layerIndex] === itemIndex
                            ? 'font-bold text-black500'
                            : 'bg-white text-black400 hover:bg-blueLight',
                          item.rightContent && 'pr-16',
                          subLayerClassName,
                        )}>
                        <span id='sub-layer-text'>{item.text}</span>
                        <RightContentRenderer content={item.rightContent} />
                      </div>
                    </li>
                  ))}
                </motion.ul>
              )}
            </AnimatePresence>
          </div>
        );
      })}
    </div>
  );
}
