import { atom } from 'jotai';
import { getLocalStorageValue } from './get-local-storage-value';
import { setLocalStorageValue } from './set-local-storage-value';

export const createUserPersistedAtom = <T>(userId: string, key: string, initialValue: T) => {
  // 사용자별 키 생성
  const storageKey = `${key}_${userId}`;

  const baseAtom = atom<T>(getLocalStorageValue(storageKey, initialValue));

  return atom(
    (get) => get(baseAtom),
    (_get, set, newValue: T) => {
      // undefined나 null인 경우 저장하지 않음
      if (newValue === undefined || newValue === null) {
        return;
      }

      // 빈 문자열이나 빈 객체 체크 (필요한 경우)
      if (
        (typeof newValue === 'string' && newValue.trim() === '') ||
        (typeof newValue === 'object' && newValue !== null && Object.keys(newValue).length === 0)
      ) {
        return;
      }

      set(baseAtom, newValue);
      setLocalStorageValue(storageKey, newValue);
    },
  );
};
